import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosShire = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="fShOvU2_avQ"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="tc74t8_by4I"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 2, Shire, Tigray 
            ረዲኤት ንተጋሩ ደቂኣንስትዮ ከም ሓንቲ ገባሪት ሰናይ ትካል ህዝቢ ትግራይ ብሰንኪ ዝወረዶ ጽንተታዊ ኲናት ምኽንያት ከምህዝቢ  ብዝበጽሖ ጽንተትን ብርሰትን፣ ዝበልዖ ይኹን 
            ዝዓርፈሉ ስኢኑ እንትሽገር ኣብዝነበርሉ እዋን፣ ትካልና ረዲኤት ኣብ ሓለፍቲ ስድራ ኣዴታት ትኹረት ገይራ፣ ኣብ ትግራይ ምስ ማሕበር ደቂኣንስትዮ ሓባራዊ ስራሕቲ ክትሰርሕ ጸኒሓ እያ። 
            እዛ ቪድዮ ኣብ ሽረ ቅድሚ እግሪ ምትካል ንስነልቦና ሕውየትን ንግዲን ስነ ቁጠባን ኣመልኪቱ ብሰብሙያ እንትስልጥና ዘርእይ እዩ።"
            />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="5Y6utvcX8CE"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 1, Shire, Tigray ረዲኤት ንተጋሩ ደቂኣንስትዮ ምስ ማሕበር ደቂኣንስትዮ ትግራይ ብምትሕብባር  
            'ድኽነት ከብቅዕ ጓል ኣንስተይቲ ነብቅዕ' ብዝብል ሓባራዊ ቴማ ኣብ ከተማ ሽረ እግሪ ክተኽላ ዓርሰን ክኽእላ ንዝተዳለዋ ሓለፍቲ ስድራ ኣዴታት ስልጠና እንትካየድ ዘርእይ።"
            />
        </div>
        <div className="gallery_item">
          
        </div>
      </div>
    </div>
  );
};

export default VideosShire;
