// Reference (source):  https://www.youtube.com/watch?v=zy8rqihtvA8&t=12s
//import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();

  //const [urlLabel, setUrlLabel] = useState("");

  //  /products/food  --->  products -> food
  console.log("------------------->>> Current location is " + JSON.stringify(location));
  let currentLink = "";
  // split the path and remove any empty strings after split
  // (example /products/food/  results in '', 'products', 'food' and '', a total of 4 elemets out of which two are empty)
  let currentPath = location.pathname;

  if (currentPath === "/" || currentPath === "") {
    // this is the home page, set it to "/home so that it is shown in breadcrumbs"
    currentPath = "/home";
  }

  const crumbs = currentPath
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      let currentLabel = "";
      if (crumb === "whoweare") {
        currentLabel = "Who We Are";
      } else if (crumb === "whatwedo") {
        currentLabel = "What We Do";
      } else if (crumb === "adietnaedier") {
        currentLabel = "Adeiet, Mahberedego (Naedier)";
      }
      return (


        <div className="crumb" key={crumb}>
          <Link to={currentLink}>
            {currentLabel
              ? currentLabel
              : crumb[0].toUpperCase() + crumb.slice(1)}
          </Link>
        </div>
        

      );
    });

  return <div className="breadcrumbs">{crumbs}</div>;
};

export default Breadcrumbs;
