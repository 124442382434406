import Navbar from "./Navbar";
import Breadcrumbs from "../content/Breadcrumbs";

const Header = () => {
  return (
    <div className="myHeader">
      <div>
        <Navbar />
      </div>
      <div className="details">
        <Breadcrumbs />
      </div>
    </div>
  );
};

export default Header;
