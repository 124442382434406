import React from "react";

const Mission = () => {
  return (
    <div className="main-content">
      <h4>Our Mission is:</h4>
      <ul>

      <li><p>
        Provide financial aid to women in Tigray affected by the genocidal war -
        Facilitate various vocational training that enables women in Tigray to
        support themselves
      </p></li>
      <li><p>
        Increase Tegaru women’s earning potential and income to allow them to
        become self-sufficient
      </p></li>
      <li><p>
        Provide financial aid to women in Tigray affected by the genocidal war -
        Facilitate various vocational training that enables women in Tigray to
        support themselves.
      </p></li>
      <li><p>
        Collaborate with partners to build the necessary infrastructure that
        would provide women in Tigray basic services such as healthcare,
        education, equitable residency, and more.
      </p></li>
      </ul>
    </div>
  );
};

export default Mission;
