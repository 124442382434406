import React from "react";

//  images (photos from hagereselam)
import hagereselam01 from "../images/hagereselam/hagereselam_IMG_5239.JPG";
import hagereselam02 from "../images/hagereselam/hagereselam_IMG_5256.JPG";
import hagereselam03 from "../images/hagereselam/hagereselam_IMG_5260.JPG";
import hagereselam04 from "../images/hagereselam/hagereselam_IMG_5309.JPG";
import hagereselam05 from "../images/hagereselam/hagereselam_IMG_5348.JPG";
import hagereselam06 from "../images/hagereselam/hagereselam_IMG_5358.JPG";
import hagereselam07 from "../images/hagereselam/hagereselam_IMG_5359.JPG";
import hagereselam08 from "../images/hagereselam/hagereselam_IMG_5390.JPG";
import hagereselam09 from "../images/hagereselam/hagereselam_IMG_5595.JPG";

const PhotosHagereselam = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam01}
              alt="Rediet Motto"
            />
            <figcaption>
              Training is part of the package provided first before giving them
              the seed fund to help them invest it wisely.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam02}
              alt="Rediet Motto"
            />
            <figcaption>
              <i>"To save Tigray save the mothers!"</i> - Rediet's motto.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam03}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam04}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam05}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam06}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam07}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam08}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={hagereselam09}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default PhotosHagereselam;
