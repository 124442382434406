import React from "react";
import { NavLink } from "react-router-dom";

const Email = () => {
  return (
    <div className="email">
      <NavLink to="https://mail.hostinger.com">Rediet Email</NavLink>
    </div>
  );
};

export default Email;
