import React from "react";

const Donate = () => {
  return (
    <div className="main-content">
      <h3> Donations</h3>
      <p>
        Make a donation to support the needy women who became victims of the
        Tigray genocidal war to help themseleves and their families. Any amount you donate be it $5, $10, $50, $100 or any amount for that
        matter helps. We highly appreciate your support and we thank you for
        your generocity. </p>
        

        <p>We are working on the online payment system and it will be added to the payment methods below in the very near future. In the meantime you can donate using any of the following methods.</p>
{/* 
      <p>
        Zeffy is our our payment processing system for collecting donations.  
      </p>
      <div className="zeffy">
        <iframe
          title="Donation form powered by Zeffy"
          src="https://www.zeffy.com/embed/donation-form/3b90db61-ecf2-4247-a3d7-f7c48691953f"
          allowpaymentrequest
          allowTransparency="true"
        ></iframe>
      </div> */}


      <p>You can donate using any of the following methods.</p>
      <ul>
        <li>
          Zelle Service: Use Rediet's email address <b>rediet.tw@gmail.com</b>.
          This transfer service is tied to our account with Bank of America.
          Hence, it will be deposited in the account shown below.{" "}
        </li>
        <br />
        <li>
          Direct deposit to Rediet's{" "}
          <b>Bank of America Account: Account Number: 435048377784</b>
        </li>
        <br />
        <li>
          Mail check to Rediet's Address:
          <br />
          <ul>3826 Koval Lane</ul>
          <ul>Woodbridge, VA 22192</ul>
          <ul>USA</ul>
        </li>
      </ul>
    </div>
  );
};

export default Donate;
