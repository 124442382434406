import React from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="myrow">
        <div>
          <p className="copyright">
            © 2021 - 2024 Rediet for Tegaru Women (Rediet)<span>⦁</span> All Rights
            Reserved{" "}
          </p>
        </div>
      </div>
      <div className="myrow">
        <div>
          <ul className="social-icons">
            <li className="facebook">
              <NavLink
                to="https://www.facebook.com/profile.php?id=100088013089763&mibextid=LQQJ4d"
                className="fa-brands fa-facebook-f"
                title="Facebook"
              />
            </li>
            <li className="twitter">
              <NavLink
                to="https://x.com/RedietFTW"
                className="fa-brands fa-x-twitter"
                title="Twitter"
              />
            </li>
            <li className="youtube">
              <NavLink
                to="https://www.youtube.com/@RedietForTegaruWomen"
                className="fa-brands fa-youtube"
                title="Email"
              />
            </li>
            <li className="email">
              <NavLink
                to=" mailto: rediet.tw@gmail.com"
                className="fa-solid fa-envelope"
                title="Email"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
