import React from 'react'

const Contact = () => {
  return (
  <div className="main-content">
    <h3>Our Contact Info</h3>
    <p>You can contact us by phone, by email or directly sending your inquiries to our head office by mail. </p>
      <div>
        <div className="contact">
          <h4>Head Office Address</h4>
          <p>3826 Koval Lane</p>
          <p>Woodbridge, VA 22192</p>
          <p>USA</p>
        </div>
        <div className="contact">
          <h4>Contact us by email</h4>
          <p>Website Admin: mebratmekonene@gmail.com</p>
          <p>General Inquires: rediet.tw@gmail.com</p>
          <p>Management Board: rediet.board@rediet4tw.org</p>
        </div>
        <div className="contact">
          <h4>Contact us by phone</h4>
          <p>Website Admin: +1-202-344-6309</p>
          <p>General Inquires: +1-703-344-8836</p>
          <p>Management Board: +1-703-399-0823</p>
        </div>
      </div>
	</div>
  )
}

export default Contact