import React from "react";
import { NavLink } from "react-router-dom";

const Membership = () => {
  return (
    <div className="main-content">
      <p>
        Rediet for Tegaru Women (Rediet) started this project in 2021. Since
        then over 130+ from several US states have become its members who
        contribute monthly. Every member contributes $10 per month or $120 per
        year. We have also a one time fee of $20 for registration. Obviously 130
        members alone do not make a big difference. We believe we can
        significantly increase our members if we reach out to as many as
        possible throught the world. If you want register as a Rediet member
        please{" "}
        <NavLink
          to="https://docs.google.com/forms/d/e/1FAIpQLSfcH7N6eknkGoy0QTJ-J8JgpUjWoGdtZx5koPgXFBSufa7_7A/viewform?pli=1"
          target="_blank"
        >
          {" "}
          register here
        </NavLink>
        . You can also <NavLink to="/contact"> contact</NavLink> us by phone,
        email, or regular mail. You will change the lives of these victims for
        ever and make a significant difference.
      </p>
    </div>
  );
};

export default Membership;
