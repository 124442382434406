//import React from "react";
import { Routes, Route } from "react-router-dom";
import "./content.css";
//import Home from "./Home";
import Home from "./Home";

import About from "./About";
import Mission from "./Mission";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import Bylaws from "./Bylaws";

import Projects from "./Projects";
import ProjectsCompleted from "./ProjectsCompleted";
import ProjectsInProgress from "./ProjectsInProgress";
import ProjectsPlanned from "./ProjectsPlanned";

import Involvement from "./Involvement";
import Membership from "./Membership";
import Volunteer from "./Volunteer";
import Videos from "./Videos";
import Audio from "./Audio";

import Gallery from "./Gallery";
import Photos from "./Photos";
import PhotosAdigrat from "./PhotosAdigrat";
import Photosadishihu from "./PhotosAdishihu";
import PhotosEnticho from "./PhotosEnticho";
import PhotosHagereselam from "./PhotosHagereselam";
import PhotosMekelle from "./PhotosMekelle";
import PhotosShire from "./PhotosShire";
import PhotosUSA from "./PhotosUSA";

import PageNotFound from "./PageNotFound";
import ProjectsCompletedAdigrat from "./ProjectsCompletedAdigrat";
import ProjectsCompletedAdiShihu from "./ProjectsCompletedAdiShihu";
import ProjectsCompletedMekelle from "./ProjectsCompletedMekelle";
import ProjectsCompletedShire from "./ProjectsCompletedShire";
import ProjectsCompletedHagereSelam from "./ProjectsCompletedHagereSelam";
import ProjectsCompletedEnticho from "./ProjectsCompletedEnticho";
import VideosUSA from "./VideosUSA";
import VideosAdigrat from "./VideosAdigrat";
import VideosAdishihu from "./VideosAdishihu";
import VideosEnticho from "./VideosEnticho";
import VideosHagereselam from "./VideosHagereselam";
import VideosMekelle from "./VideosMekelle";
import VideosShire from "./VideosShire";
import ProjectsCompletedAdiet from "./ProjectsCompletedAdiet";
import ProjectsCompletedNaedier from "./ProjectsCompletedNaedier";

import Donate from "./Donate";
import Contact from "./Contact";
import Email from "../header/Email";
import Testimonials from "./Testimonials";

const Content = () => {
  return (
    <div className="content">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/about/mission" element={<Mission />} />
        <Route exact path="/about/whoweare" element={<WhoWeAre />} />
        <Route exact path="/about/whatwedo" element={<WhatWeDo />} />
        <Route exact path="/about/bylaws" element={<Bylaws />} />
        <Route exact path="/about/testimonials" element={<Testimonials />} />

        <Route exact path="/projects" element={<Projects />} />
        <Route
          exact
          path="/projects/completed"
          element={<ProjectsCompleted />}
        />
        <Route
          exact
          path="/projects/completed/adiet"
          element={<ProjectsCompletedAdiet />}
        />
        <Route
          exact
          path="/projects/completed/adigrat"
          element={<ProjectsCompletedAdigrat />}
        />
        <Route
          exact
          path="/projects/completed/adishihu"
          element={<ProjectsCompletedAdiShihu />}
        />
        <Route
          exact
          path="/projects/completed/enticho"
          element={<ProjectsCompletedEnticho />}
        />
        <Route
          exact
          path="/projects/completed/hagereselam"
          element={<ProjectsCompletedHagereSelam />}
        />
        <Route
          exact
          path="/projects/completed/naedier"
          element={<ProjectsCompletedNaedier />}
        />
        <Route
          exact
          path="/projects/completed/mekelle"
          element={<ProjectsCompletedMekelle />}
        />
        <Route
          exact
          path="/projects/completed/shire"
          element={<ProjectsCompletedShire />}
        />

        <Route
          exact
          path="/projects/inProgress"
          element={<ProjectsInProgress />}
        />
        <Route exact path="/projects/planned" element={<ProjectsPlanned />} />

        <Route exact path="/involvement" element={<Involvement />} />
        <Route exact path="/involvement/membership" element={<Membership />} />
        <Route exact path="/involvement/volunteer" element={<Volunteer />} />
        <Route exact path="/involvement/donate" element={<Donate />} />

        <Route exact path="/donate" element={<Donate />} />

        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/gallery/videos" element={<Videos />} />
        <Route
          exact
          path="/gallery/videos/adigrat"
          element={<VideosAdigrat />}
        />
        <Route
          exact
          path="/gallery/videos/adigrat"
          element={<VideosAdigrat />}
        />
        <Route
          exact
          path="/gallery/videos/adishihu"
          element={<VideosAdishihu />}
        />
        <Route
          exact
          path="/gallery/videos/enticho"
          element={<VideosEnticho />}
        />
        <Route
          exact
          path="/gallery/videos/hagereselam"
          element={<VideosHagereselam />}
        />
        <Route
          exact
          path="/gallery/videos/mekelle"
          element={<VideosMekelle />}
        />
        <Route exact path="/gallery/videos/shire" element={<VideosShire />} />
        <Route exact path="/gallery/videos/USA" element={<VideosUSA />} />

        <Route exact path="/gallery/photos" element={<Photos />} />
        <Route
          exact
          path="/gallery/photos/adigrat"
          element={<PhotosAdigrat />}
        />
        <Route
          exact
          path="/gallery/photos/adishihu"
          element={<Photosadishihu />}
        />
        <Route
          exact
          path="/gallery/photos/enticho"
          element={<PhotosEnticho />}
        />
        <Route
          exact
          path="/gallery/photos/hagereselam"
          element={<PhotosHagereselam />}
        />
        <Route
          exact
          path="/gallery/photos/mekelle"
          element={<PhotosMekelle />}
        />
        <Route exact path="/gallery/photos/shire" element={<PhotosShire />} />
        <Route exact path="/gallery/photos/USA" element={<PhotosUSA />} />

        <Route exact path="/gallery/audio" element={<Audio />} />

        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/email" element={<Email />} />

        {/* This should always be the last one - catch all */}
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </div>
  );
};

export default Content;
