import React from 'react'

const WhatWeDo = () => {
  return (
    <div className="column">
    <p>
      Rediet for Tegaru Women (Rediet) is a non-governmental, non-political,
      and not-for-profit charity organization with approved 501(C) (3) tax
      exempt status in the United States incorporated to support women
      heavily affected by the genocidal war in Tigray, Northern Ethiopia.
      Rediet started when a group of diaspora community organizers,
      primarily mothers, recognized that the burden of war in Tigray,
      northern Ethiopia, would be most felt by women, especially by women
      heads of households. Who lost their income and means to support their
      families during the war. Organizers created a community-based
      nonprofit called Rediet for Tegaru Women in 2021. The mission of
      Rediet is to provide financial aid for women, prioritizing displaced
      women heads of household and helping women affected by the war in
      Tigray become economically self-supporting.
    </p>
    <p>
      Since its incorporation in 2021 Rediet for Tegaru Women has been
      helping families priorizing the most affected ones identified by
      experts on the ground. The mission of Rediet is to provide financial
      aid for women, prioritizing displaced women heads of household and
      helping women affected by the war in Tigray to become economically
      self-supporting. It has been providing them with cash monthly to feed
      their families and then training and re-establishing them so that they
      become self sufficient and self reliant.
    </p>
    <p>
      Working with the Women’s Association of Tigray, a nonprofit located
      within Tigray, Rediet for Tegaru Women, for nearly three years, has
      financially supported over 190 women heads of households. Since the
      sighning of the Pretoria agreement between the Federal Government of
      Ethiopia and the Tigray People's Liberation Front in October 2022,
      Rediet has pivoted by training women heads of households on
      entrepreneurship fundamentals and giving them seed funds to start and
      helping them to establish their own small enterprises.
    </p>
  </div>
  )
}

export default WhatWeDo