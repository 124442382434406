import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosUSA = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="nX8Q2z3GoXY"
            inputWidth="360"
            inputHeight="338"
            caption="Fundrasing: Makda Negassi, Eldana Kibrom and Arsema Negassi"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="Hb4MQkxSyw4"
            inputWidth="360"
            inputHeight="338"
            caption="Our sister, Makda Bihon, in fundraing action - Alexandria, VA, 2022"
          />
        </div>

        <div className="gallery_item">
          <YoutubeEmbed
            embedId="INS3JVxOaq4"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet event - Arlington VA, may 2022"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="ndL6uCAxbGE"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet event - Arlington VA, 2022"
          />
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="dHdRlpnDZSk"
            inputWidth="360"
            inputHeight="338"
            caption="Inteview with volunteer youngsters - Alexandria VA, 2022"
          />
        </div>
        <div className="gallery_item">
        <YoutubeEmbed
            embedId="wLsJMQE-wxY"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet fundraiser, Alexandria VA / ረዲኤት ንተጋሩ ደቂ-ኣንስትዮ ብሰንኪ ጽንተታዊ ጦርነት ኣዝየን 
            ንዝተጎዳኣ ሓጋዚ ዘይብለን ደቂኣንስትዮ ትግራይ ንምሕጋዝ ምስ ኣባላታን ፈተውታን እቶት ንምርካብ ምድላዋት ትገብር።
            እዚ ኣብ Alexandria, VA  Sep. 2022  ዝነበረ ዋዕላ ስድራን ፈተውቲን ረዲኤት እዩ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="km-D42Rg0Rg"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet Fundraiser, Alexandria /ረዲኤት ንተጋሩ ደቂ-ኣንስትዮ ብሰንኪ ጽንተታዊ ጦርነት ኣዝየን ንዝተጎዳኣ ሓጋዚ ዘይብለን ደቂኣንስትዮ 
            ትግራይ ንምሕጋዝ ምስ ኣባላታን ፈተውታን እቶት ንምርካብ ምድላዋት ትገብር። እዚ ኣብ Alexandria, VA  Sep. 2022 
            ዝነበረ ዋዕላ ስድራን ፈተውቲን ረዲኤት ንተጋሩ ደቂኣንስትዮ እዩ።"
          />
        </div>
        <div className="gallery_item">
          
        </div>
      </div>
    </div>
  );
};

export default VideosUSA;
