import React from "react";
import { NavLink } from "react-router-dom";

const Bylaws = () => {
  return (
    <div className="main-content">
      <div className="center rounded">
        <h2>Our Bylaws</h2>
      </div>
      <div className="bylaws_row">
        <div className="column english">
          <h4>Bylaws of Rediet for Tegaru Women (Rediet)</h4>

          <h4>Introduction</h4>
          <p>
            In November 2020, the government of Ethiopia declared war against
            Tigray. During the war, it carried out unilateral mass killings of
            tens of thousands of Tigrayans and, destruction of private and
            government properties and public services, which has never been seen
            or heard of in modern history.
          </p>
          <p>
            This brutal war knocked on the door of every Tigrayan family,
            severely affecting the women and children of Tigray, in particular.
            On one side, the woman has lost her spouse and child, on the other
            side, she has been a victim of both physical and psychological
            damage. Hence, she has become a major victim of the war.
          </p>
          <p>
            Therefore, understanding the severe problems that currently Tigrayan
            women are passing through, we, Tigrayans who live outside Tigray,
            without differences in gender, political opinion, religion, or race,
            have agreed to help the mothers and sisters on a humanitarian basis,
            within the limits of our power. To realize this objective, we have
            established a nonprofit humanitarian organization.
          </p>
          <p>
            To read the whole document please downlaod the
            <NavLink target="blank" to="/docs/bylaws/RedietBylawsEnglish.pdf">
              {" "}
              Rediet Bylaws in PDF format{" "}
            </NavLink>
          </p>
        </div>
        <div className="column geez">
          <h4>ሕገ ደንቢ</h4>
          <h5> ረዲኤት ንደቂ አንስትዮ ትግራይ</h5>
          <p>መእተዊ</p>
          <p>
            ኣብ ወርሒ ጥቅምቲ 2013 ብግእዝ ኣቆጻጽራ ወይ ብኣውሮፓውያን ኣቆጻጽራ ኦክቶበር/2020 መንግስቲ ኢትዮጵያ
            ኣብ ልዕሊ ትግራይ ብዝኣወጆ ኲናት ኣብ ልዕሊ ህዝብናን ትካላትናን ዘብጽሖ ማእለያ ዘይብሉ፣ ናይሰብ ሂውት
            መቕዘፍትን፣ ኣካል ጉድኣትን፣ ምብራስ ንብረትን፣ ንምግላጽ ዝኸብድን ንምስምዑ ዘስካሕክሐ እዩ።{" "}
          </p>
          <p></p>እዚ ዝመረረ ግፍዒ ኣብ ኩሉ ገዛ ህዝቢ ትግራይ ዝኣተወ እንትኸውን ብዝኸፍአ መልክዑ ንደቂ ኣንስትዮን
          ህጻናትን ዝሃሰዮም ኮይኑ ይርከብ። እታ ጓል ኣንስተይቲ ባዓልቤታ፣ ውላዳ፣ ብኲናት ዝሰኣነት፣ ኣብ ባዕላ ናይ
          ኣካልን ስነ ኣእምሮ ማህሰይቲን ዝበጽሓ፣ ናይቲ ኲናት ቀዳመይቲ ተሃሳይት ኮይና ትርከብ፤ ንሳውን እታ ካብ ሞት
          ዝተረፈት እያ።
          <p>
            &gt;ካብዚ ተላዒልና ካብሃገርና ወጻኢ እንርከብ ግዱሳት ተወለድቲ ትግራይ ብዘይ ምንም ዓይነት ናይ ፖለቲካ፣
            ጾታ፣ ሃይማኖት፣ ዓሌት፣ ኣፈላላይ ብሰብኣዊነትና ተለዓዒልና ንኹለን ኣብ ጸገም ዘለዋ ንምሕጋዝ ዓቕሚ
            እንተሓጸረና እኳ ንውሕዳት ብጣዕሚ ዝተጸገማ ኣደታትን ኣሓትን ንምሕጋዝ መንግስታዊ ዘይኮነ፣ ንመኽሰብ
            ዘይንቀሳቐስ ናይ ረድኤት ትካል ንኸነጣይሽ ግድን ምስኮነና፣ ረዲኤት፣ ዝመጸዊዕኡ ሰብኣዊ ትካል ክንምስርት
            ክኢልና።{" "}
          </p>
          <p>
            To read the whole document download the PDF document {}
            <NavLink target="blank" to="/docs/bylaws/RedietBylawsTigrigna.pdf">
              ሕገ ደንቢ ረዲኤት ንደቂ ኣንስትዮ ትግራይ
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bylaws;
