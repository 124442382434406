import React from "react";

import adiet01 from "../images/adiet/adiet_PHOTO-2024-09-03-18-59-43.jpg";
// import adigrat02 from "../images/adigrat/adigrat_857A4633.JPG";
// import adigrat03 from "../images/adigrat/adigrat_857A4638.JPG";

const ProjectsCompletedAdiet = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div>
          <p>
            I am excited to share information about our farmer women
            beneficiaries. As you know, this is a crucial time for farming in
            Tigray to ensure a successful harvest. Keeping this in mind, we
            reached out in time to support 100 farmer women who were unable to
            farm their lands. We provided them with farming equipment, seeds,
            fertilizer, and chicken along with their food. Additionally, they
            received training to help alleviate their stress and build their
            strength.
          </p>
          <p>
            This achievement is due to your dedication, commitment, and
            unwavering support, no matter where you are. You can watch the video
            below to see how grateful they are for the support they received,
            which motivates us to work even harder together.
          </p>

          <p>
            With that in mind the first action Rediet for Tegaru Women (Rediet
            in short) took was to provide speciall short term training to
            selected group of individuals. Once they are found to be capable of
            running a mini businesss seed fund was provided to them.
          </p>

          <p>
            These was provided to around 30 war displaced and needy women who
            were living in Mekelle and the environs. The type of training needed
            and duration was determined in consulation with the academic
            institutions in Tigray and the Women's Association of Tigray. The
            training fee (although most instructors provided the for almost
            free), perdiem, and the expenses for meals was provided by Rediet.
          </p>

          <p>
            Before, during, and after after completion of the training each
            individual trainee came up with their propopsals on what they want
            to do with the seed money that will be provided to them. This was
            further discussed with the experts on the ground and the Women
            Association of Tigray to valiadate the viability of the propposed
            projects.
          </p>
          <p>
            Finally each was given the necessary seed fund and they started
            their own mini-investments. The women's assication continously
            follows and minotors their progress and they provide advice if
            needed.
          </p>
        </div>
        <div className="gallery-item">
          <figure>
            <img className="responsive" src={adiet01} alt="Adiet and Mahbere Dego (Naedier)" />
            <figcaption>
              Training is part of the package provided first before giving them
              the seed fund to help them invest it wisely.
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCompletedAdiet;
