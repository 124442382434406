import React from "react";
import { NavLink } from "react-router-dom";

//import testimonial from "../images/mekelle/testimonials.jpg";

const Testimonials = () => {
  return (
    <div className="rediet-flex-direction">
      <div className="testimonial">
        <NavLink
          target="blank"
          to="/docs/testimonials/mekelle_testimonial-2023-05-12-09-09-10.pdf"
        >
          <figure>
            <img
              className="responsive"
              src={require("../images/testimonials/mekelle_testimonial-2023-05-12-09-09-10.jpg")}
              alt="Rediet Motto"
            />
            <figcaption>
              Certificate of Greatfullness and Acknowledgement
            </figcaption>
            <div className="getGoodName">
              <p>
                Testmonial given to Rediet for Tegaru Women (Rediet) from
                Women's Association of Tigray (WAT).
              </p>
            </div>
          </figure>
        </NavLink>
      </div>
      <div className="testimonial">
        <NavLink target="blank" to="/docs/testimonials/ThankyouLetterECDC_2023_08_08.pdf">
          <figure>
            <img
              className="responsive"
              src={require("../images/testimonials/ecdc.png")}
              alt="Rediet Motto"
            />
            <figcaption>
              Thank you letter from Ethiopian Community Development Center, Inc.
              Arlington VA, USA
            </figcaption>
            <div className="getGoodName">
              <p>
                Testmonial given to Rediet for Tegaru Women (Rediet) from
                Ethiopian Community Development Center in recognotion of its
                donation of teaching materials to ECDC to be used in Tigray.
              </p>
            </div>
          </figure>
        </NavLink>
      </div>
      <div className="testimonial">
        <NavLink
          target="blank"
          to="/docs/testimonials/ThankyouLetterAbrahaWeAtsbaha_2023_08_08.pdf"
        >
          <figure>
            <img
              className="responsive"
              src={require("../images/testimonials/abraha_atsbaha.png")}
              alt="Rediet Motto"
            />
            <figcaption>
              Thank you letter from Abraha WeAtsbaha Elementary School, Axum
              Tigray
            </figcaption>
            <div className="getGoodName">
              <p>
                Thank you letter sent by Abraha WeAtsbaha Elementary School,
                Axum Tigray to Rediet for Tegaru Women (Rediet) in recognotion
                of its donation of teaching materials to the school.
              </p>
            </div>
          </figure>
        </NavLink>
      </div>
    </div>
  );
};

export default Testimonials;
