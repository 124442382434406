import React from "react";
import { NavLink } from "react-router-dom";
import HomeLeft from "./HomeLeft";
import HomeRight from "./HomeRight";
import ProjectsCompletedLeft from "./ProjectsCompletedLeft";
import ProjectsCompletedRight from "./ProjectsCompletedRight";

const ProjectsCompleted = () => {
  return (
    <div className="projects">
      <div className="submenu">
        <div>
          <NavLink to="/projects/completed/adiet">Adiet</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/adigrat">Adigrat</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/adishihu">Adishihu</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/enticho">Enticho</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/hagereselam">Hagere Selam</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/mekelle">Mekelle</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/naedier">Naedier</NavLink>
        </div>
        <div>
          <NavLink to="/projects/completed/shire">Shire</NavLink>
        </div>
      </div>

      
      <div className="main rediet-flex-direction">
        <div>
          <ProjectsCompletedLeft />
        </div>
        <div>
          <ProjectsCompletedRight />
        </div>
      </div>

      {/* <div className="summary">
        <div className="summary-table">
          <table>
            <tr>
              <th>No</th>
              <th>Project</th>
              <th>Households</th>
              <th>Family Size in Households</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Adiet</td>
              <td>50</td>
              <td>260</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Adigrat</td>
              <td>30</td>
              <td>131</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Adishihu</td>
              <td>30</td>
              <td>Not yet counted</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Enticho</td>
              <td>30</td>
              <td>140</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Hagere Selam</td>
              <td>30</td>
              <td>Not yet counted</td>
            </tr>

            <tr>
              <td>6</td>
              <td>Mekelle</td>
              <td>30</td>
              <td>146</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Naedier</td>
              <td>50</td>
              <td>211</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Shire</td>
              <td>30</td>
              <td>120</td>
            </tr>
            <tr>
              <td></td>
              <td>Total</td>
              <td>280</td>
              <td>Approximately 1000+</td>
            </tr>
          </table>
        </div>
        <p>
          Trauma, as a result of the Tigray Genocide has significant impact on
          the lives of every one in Tigray. An estimated 1 million Tigrayans
          have persihed in this war. Women were not an exception. Many have been
          raped, killed, displaced, their properies destroyed and/or looted. The
          problems are endless and it is beleived that the first step in the
          rehabilitation of these women is to provide them with some sort of
          physcolgical treament. Without this type of treament any financial
          support provided might not be effective. With that in mind, the first
          action Rediet for Tegaru Women (Rediet in short) took was to provide
          special short term trainings to selected group of individuals. Once
          they are found to be in a position to be able to run their own mini
          enterprises, seed fund was provided to them for that.
        </p>

        <p>
          Initially this was provided to around 30 war displaced women heads of
          households who were living in Mekelle and the environs. The type of
          training needed and duration was determined in consulation with the
          academic institutions in Tigray and the Women's Association of Tigray.
          The training fee (although most instructors provided the service for
          almost free), perdiem, and the expenses for meals was provided by
          Rediet.
        </p>

        <p>
          Before, during, and after after the training each individual trainee
          came up with their propopsals on what they want to do with the seed
          money that will be provided to them. This was further discussed with
          the experts on the ground and the Women's Association of Tigray to
          valiadate the viability of the propposed projects. Finally once this
          was determined each trainee was given the necessary seed fund to
          support themseleves by starting and running their own
          mini-investments. The women's assiation continously follows and
          minotors their progress and they provide advice as needed.
        </p>
      </div>  */}
    </div> 
  );
};

export default ProjectsCompleted;
