import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosEnticho = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
      <div className="gallery_item">
          <YoutubeEmbed
            embedId="8uWbDyeyrcM"
            inputWidth="360"
            inputHeight="338"
            caption="Beneficiaries Interview in Enticho, Tigray"
          />
        </div>       
       <div className="gallery_item">
          <YoutubeEmbed
            embedId="UFmH_0-CvV8"
            inputWidth="360"
            inputHeight="338"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="zs-uOGqnVs4"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 3, Enticho, Tigray 
            ረዲኤት ንተጋሩ ደቂኣንስትዮ ከምትካል ካብዝሓዘቶም ዕላማታት ሓደ፣ ደቂ ኣንስትዮ ትግራይ ዓርሰን ክኽእላ ዓርሰን ክኢለን ስድረአን ከናብያ ምሕጋዝ እዩ። 
            ብሰንኪ ጽንተታዊ ኲናት ትግራይ ኣብ ህዝቢ ንዝብጽሐ ሞትን ብርሰትን ድኽነትን ንምምንጋፍ ረዲኤት ብዓቕማ ኣብ ዝተፈላለየ ወረዳታት ትግራይ ናይ ስነልቦና ሕውየትን ናይ ገንዘብ 
            ኣታሃህዛን ንግዲን ስልጠና ብምሃብ እግሪ መትከሊ ሓገዝ 20,000 ብር ነንሕድሕደን ብምሕጋዝ ኣብስራሕ ክዋፈራ ገይራ እያ። እዚ ቪዲዮ ኣብ እንትጮ ካብዝነበረ ስልጠና ሓንቲ ዝሰልጠነት እዶ ርእይቶ ዝተወሰደ እዩ፣"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="a75xWHYbASg"
            inputWidth="360"
            inputHeight="338"
          />
        </div> 
      </div>
    </div>
  );
};

export default VideosEnticho;
