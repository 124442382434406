import React from 'react'

const ProjectsInProgress = () => {
  return (
    <div>
      <h3>Supporting 300 Women in Adiet and Mahbere Dego (Naedier)</h3>
      <p>
        Support 300 Women households to start their own mini businesses and to
        make them self reliant. Fund these women for their mini businesses and
        provide them the necessary training to achieve this goal.
      </p>
    </div>
  );
}

export default ProjectsInProgress