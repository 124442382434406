import React from "react";
import { NavLink } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";
import enticho02 from "../images/enticho/enticho_1973.jpg";
import rediet_documentary from "../audio/rediet_documentary.m4a";
const Gallery = () => {
  return (
    <div className="main-content">
      <div className="submenu">
        <div>
          <NavLink to="/gallery/videos">Videos</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos">Photos</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/audio">Audio</NavLink>
        </div>
      </div>
      <div className="rediet-flex-direction">
        <div className="tiles">
          <YoutubeEmbed
            embedId="FFW-O7V8prU"
            inputWidth="360"
            inputHeight="300"
            caption="Beneficiaries are speaking!"
          />
        </div>
        <div className="tiles">
          <figure>
            <img className="responsive" src={enticho02} alt="Rediet Motto" />
            <figcaption>
              <i>Group discussion going on during training </i>
            </figcaption>
          </figure>
        </div>
        <div className="tiles audio">
          <audio controls>
            <source src={rediet_documentary} />
            Your browser does not support the audio element.
          </audio>
          <figcaption>
            <i>Rediet documentary audio</i>
          </figcaption>
        </div>
      </div>

      <div className="column">
        <p>
          The gallery is where we keep a complete list of Rediet related media
          in a form of videos, photos, and audios. These are gathered primarily
          from Tigray where our beneficiaries are. But we may also include media
          from other places where our members live and do the fund rasing
          activities throughout the world.
        </p>
      </div>
    </div>
  );
};

export default Gallery;
