import React from 'react'

const ProjectsCompletedRight = () => {
  return (

    <div className="right-inner">
       
        <iframe src="https://www.google.com/maps/d/embed?mid=1WCEKd6ON54oNdcpwxQtf-K-_UIfbHUY&ehbc=2E312F" width="640" height="480"></iframe>

    </div>
    

  )
}

export default ProjectsCompletedRight