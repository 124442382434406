import React from "react";

//  images (photos from shire)
import shire01 from "../images/shire/shire_PHOTO-2024-01-15-23-15-29.jpg";
import shire02 from "../images/shire/shire_PHOTO-2023-05-18-11-50-36.jpg";

const PhotosShire = () => {
  return (
    <div className="main-content">
          <div className="gallery_group">
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={shire01}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Training is part of the package provided first before giving
                  them the seed fund to help them invest it wisely.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={shire02}
                  alt="Rediet Motto"
                />
                <figcaption>
                  <i>"To save Tigray save the mothers!"</i> - Rediet's motto.
                </figcaption>
              </figure>
            </div>
  {/*           <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={shire03}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery_group">
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={shire04}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={shire05}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={shire06}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div> */}
      </div>
    </div>
  );
};

export default PhotosShire;
