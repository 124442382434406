import React from "react";
import { NavLink } from "react-router-dom";
// import rediet_motto from "../images/adigrat_training01.jpg";
// import save_mother from "../images/save_mother_save_tigray.jpg";
// import seed_fund from "../images/seed_fund_ready.jpg";

const Photos = () => {
  return (
    <div className="main-content">
      <div className="submenu">
        <div>
          <NavLink to="/gallery/photos/adigrat">Adigrat</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos/adishihu">Adishihu</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos/enticho">Enticho</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos/hagereselam">Hagere Selam</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos/mekelle">Mekelle</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos/shire">Shire</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/photos/USA">USA</NavLink>
        </div>
      </div>
      <div className="summary">
        <div className="gallery-summary">
          <div className="photos">
            <figure>
              <img
                className="responsive"
                src={require("../images/save_mother_save_tigray.jpg")}
                alt="Rediet Motto"
              />
              <figcaption>
                Seed fund ready to help the needy women to established their
                choice of mini-investiment.
              </figcaption>
              <div className="getGoodName">
                <h4>We identify the most affected ones </h4>
              </div>
            </figure>

            <figure>
              <img
                className="responsive"
                src={require("../images/adigrat_training01.jpg")}
                alt="Rediet Motto"
              />
              <figcaption>
                Training is part of the package provided first before giving
                them the seed fund to help them invest it wisely.
              </figcaption>
              <div className="getGoodName">
                <h4>We train them</h4>
              </div>
            </figure>
            <figure>
              <img
                className="responsive"
                src={require("../images/seed_fund_ready.jpg")}
                alt="Rediet Motto"
              />
              <figcaption>
                <i>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </i>
              </figcaption>
              <div className="getGoodName">
                <h4>We help them to be self reliant </h4>
              </div>
            </figure>

            {/* <figure>
              <img 
                className="responsive"
                src={require("../images/save_mother_save_tigray.jpg")}
                alt="Rediet Motto"
              />
              <figcaption>
                Seed fund ready to help the needy women to established their choice of mini-investiment.
              </figcaption>
              <div className="getGoodName">
              <h4>We help them to be self reliant </h4>
            </div>
            </figure> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Photos;
