import React from "react";
import { NavLink } from "react-router-dom";
// import adigrat03 from "../images/adigrat/adigrat_1859.jpg";
import seed_fund from "../images/seed_fund_ready.jpg";
import hagereselam01 from "../images/hagereselam/hagereselam_IMG_5239.JPG";
import save_mother from "../images/save_mother_save_tigray.jpg";

const Projects = () => {
  return (
    <div className="main-content">
      <div className="submenu" id="projects">
        <div>
          <NavLink to="/projects/completed">Completed</NavLink>
        </div>
        <div>
          <NavLink to="/projects/inProgress">In Progress</NavLink>
        </div>
        <div>
          <NavLink to="/projects/planned">Planned</NavLink>
        </div>
      </div>
      <div className="summary">
        <div className="rediet-flex-direction">
          <div className="tiles">
          <div className="getGoodName">
              <h4>We identify the most affected ones</h4>
            </div>

            <figure>
              <img
                className="responsive"
                src={save_mother}
                alt="Rediet Motto"
              />
              <figcaption>
                With the help of our partners on the ground, the Tigray Women Association, a 
                non-governmental and non-profit organization, we identify the most
                vulnerable women that should be helped
              </figcaption>
            </figure>
          </div>
          <div className="tiles">
          <div className="getGoodName">
              <h4>We train them</h4>
            </div>
            <figure>
              <img
                className="responsive"
                src={hagereselam01}
                alt="Rediet Motto"
              />
              <figcaption>
                Training is part of the package provided first before giving
                them the seed fund to help them invest it wisely.
              </figcaption>
            </figure>
          </div>
          <div className="tiles">
          <div className="getGoodName">
              <h4>We help them to be self reliant </h4>
            </div>

            <figure>
              <img className="responsive" src={seed_fund} alt="Rediet Motto" />
              <figcaption>
                Seed fund ready to help the needy women to established their
                choice of mini-investiment.
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
