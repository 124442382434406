import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosUSA = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="TbdlAyQuMOo"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training 2023 Part 9, Hagere Selam, Tigray ረዲኤት ንተጋሩ ደቂኣንስትዮ ብሰንኪ ጽንተታዊ ኲናት 
            ትግራይ ናብረአንን ስድረአንን በሪሶ ዓቕሚ ዝሰኣና ኣዴታት ትግራት ዓቕሚ ፈጢረን ንኽነብራ ሰሪሐን ዓርሰን ንኽኽእላ እትሕግዝ ገባሪት ሰናይ ትካል እያ። ረዲኤት እንካብ ኣወፊዮም 
            ዘይደኽሙ ኣባላታን ፈተውታን እትረኽቦ ገንዘብ ኩሉ ኣብ ምሕጋዝን ምቁቛምን ኣዴታት ትግራይ እዩ ዝውዕል። ዕላማ ረዲኤት ንኽትግበር ማሕበር ደቂኣንስትዮ ትግራይ ኣብ ትግራይ 
            መሳርሕትናን ናይ ትግበራ ኣጋርና  እየን። ኣዶ ወንበር እቲ ማሕበር ወይዘሮ ኣበባ ሃይለስላሰ ምስ ካልኦት መሳርህታ ብምዃን ረዲኤት ንተጋሩ ደቂኣንስትዮ ዘለዋ ዓላማ ኣብሽቱኡ 
            ንኽውዕል ኣብኩለን እግሪ ዘትከልናለን ወረዳታት ትግራይ እናተረኸበት ኣዴታት ተታሓባብር፣ ተበረታትዕ፣ ዕላማ ረዲኤት ንተጋሩ ደቂኣንስትዮ ተረድአን። 
            ኣብዚ እውን ኣብ ሃገረሰላም ተረኺባ በቲ እዋን መርድእ ህዝቢ ትግራይ፣ ነተን ኣዴታት ኣብምጽንናዕን ናብዝነበርናዮ ክንምለስን ሰሪሕና ክንነብር ንኽእል ናብዝብል ኣታሓሳስባ ንኽምለሳ 
            መላዓዓሊ ምኽሪ እንትትህብ ዘርእይ እዩ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="gX4YVQc7CIo"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training, 2023 Part 8, Hagere Selam, Tigray ረዲኤት ንተጋሩ ደቂኣንስትዮ ነዴታት ትግራይ ንምሕጋዘ 
            ኣብዝተገበረ ሰብኣዊ ሓገዝ ኩሉ ናይ ንግዲን ስነ ቁጠባን ባዓልቲ ሙያ ወይዘሮ ኣበባ ኩልሻብ ምሳና ኣላ። ከምቲ ኩልሻዕ እትገብሮ ሙያዊን ወገናዊን ሓላፊነታ ብሰብ ኣዊነት  
            እንትትዋጽእ ሎሚውን ኣብ ሃገረሰላም ተረኺባ ቅድሚ እግሪ ምትካል ኣዴታት እንትተሰልጥን ዘርእይ እዩ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="wqrDj0E-G98"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 7, Hagere Selam, Tigray, 2023
            ረዲኤት ንተጋሩ ደቂኣንስትዮ እግሪ ከተትክለን ስልጠና እንካብ ዝተውሃበን ውሱናት ኣዴታት ትግራይ ሃደ ኣብ ወረዳ ሀገረሰላም እዩ። ኣብዙይ እውን ጀጋኑ ኣዴታት ትግራይ ኣብ እዋን 
            ስልጠና ርእይቶ እንትህባ፣ ሕሉፍ ተሞክሮአን እንተካፍላ ዘርእይ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="IFUG78mFIEY"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 6, Hagere Selam, Tigray 
            ረዲኤት ንተጋሩ ደቂኣንስትዮ ኣብ ኩለን ሓገዝ ገይራ እግሪ ዘትከለትለን ወረዳታት፣ ቅድሚ ኩሉ እተን ንኽሕገዛ ዝተመረጻ ኣዴታት ብምትሕብባር ማሕበር ደቂኣንስትዮ ትግራይን መሰልጠንቲ ሰብሙያን 
            ናይ ሰለስተ ማዓልቲ ስነልቦናዊ ሕውየት ክረኽባ፣ ናይ ገንዘብ ኣታሓሕዛን ቁጠባን ከመኡውን ናይ ይኽእልየ ስምዒትን ኣመልኪቱ ስልጠና ይወሃበን እዩ። ኣብዙይውን ባዓልቲ ሙያን ናይ ኩሉ እዋን ተሓባብሪት 
            ረዲኤት ወይዘሮ ኣበባ ስልጠና እንትትህብ።"
          />
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="F8ZTTgioChA"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 5, Hagere Selam, Tigray, 2023/
            ረዲኤት ንተጋሩ  ደቂ ኣንስትዮ ምስ ማሕበር ደቂኣንስትዮ ትግራይ ኣብ ሃገረሰላም ነዴታት  ስልጠና እንትተውህብ። ኣዴታት ትግራይ ኢድ ሰብ ተጽበይቲ ዘይኮናስ ብዓርሰን ጽዒረን ግዒረን ዓርሰን ክኢለን ስድረአን 
            ዘናብያ ጀጋኑ ፈተውቲ ስራሕ እየን። እዘን ኣዴታት ብኸምዚ ዘጥረየኦ ንብረተንን ጥሪተንን ወረርቲ ኣብትግራይ ኣትዮም ዘሚቶሞ፣ ኣቃጺሎሞ። ኣዴታት እውን ተቐቲለን፣ ተደፊረን ኣብ ሕሱም ድኽነት ክወድቃ ተገዲደን። 
            ነዘን ኣዴታት እዚአን እያ ረዲኤት ዓቕማ ብዝፈቐዶ ሎሚ ንብዓተን ክትሕብስ፣ ኣሰልጢና እግሪ ክተኽላ ንቕድሚት ዓርሰን ክኽእላ ላዕሊን ታሕቲን እትብል ዘላ። እዛ ኣዶ ሓንቲ እንካፍተን ጀጋኑ ተጋሩ ሕሉፍ ተሞክሮኣ 
            ተካፍለና ኣላ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="1w_hOexP4K0"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 4, Hagere Selam, Tigray
            ረዴት ንተጋሩ ደቂኣንስትዮ ኣብ ሃገረሰላን፣ ትግራይ ብሓገዝ ረዲኤት እግሪ ንኽተኽላ ዝተመልመላ ኣዴታት፣ ቅድሚ ስራሕ ምጅማረን ናይ ስነልቦና ሕውየትን ናይ ስነቁጠባን ንግዲን ስልጠና ብሰብሙያ እንትዋሃበን።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="PcoYxUaLN_s"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 3, Hagere Selam, Tigray
            ገባሪት ሰናይ ትካል ረዲኤት ንተጋሩ ደቂ-ኣንስስትዮ፣ ብሰንኪ ጽንተታዊ ዂናት ትግራይ፣ ኣብድኽነትን ሽግርን ዝወደቓ ኣዴታት ትግራይ ዓቕሚ ክረኽባ እንክትሕግዘን መጀመሪያ እንካፍቲ ሕሉፍ 
            መከራን ሽግርን ኣእምሮአን ክጸርይ ብሰብሙያ ዝተሓገዘ ናይ ስነልቦና ትምህርቲ ይወሃበን፣ ብምቕጻል እቲ ዝወሃበን እግሪ መትከሊ ገንዘብ ብኣግባቡ ክጥቀማሉ ዝሕግዘን ስልጠና  ይወሃበን። 
            ነዚ ኣመልኪቱ ኣብቲ ከተማ ምምሕዳር ዝኾኑ ምስጋናን ርእይቶን እንትህቡ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="azJlJdR9fQI"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 2, Hagere Selam, Tigray ፣2023
            ረዲኤት ንተጋሩ ደቂኣንስትዮ  ምስ ማሕበር ደቂኣንስትዮ ትግራይ ኣብሃገረሰላም ኣብዝተረኸበትሉ እዋን፣ ኣዴታት ትግራይ መስዋቲ ጀጋኑ ደቀን ኩሎም ዝዉኣት ጀጋኑን መርድእ ዝተነገረሉ እዋን ነይሩ። 
            በዚ ምኽንያት ንኸሰልጥኑ ዝኸዱ ሰብሙያ ኣዴታት እንካብ ሓዘነን ንኽወጻ ዝኽእል ምኽሪን መጸናንዒን፣ ናይ ስነ ልቦና ተሃድሶ ንኽረኽባ እንካብ ድሕረ ኲናት ዝፍጠር ፍርሒን ጭንቂን ክፍወሳ 
            ዘኽእል ትምህርቲን ስልጠናን፣ እንካብ ረዲኤት ንተጋሩ ደቂኣንስትዮ ዝወሃበን ናይ እግሪ መትገሊ ሓገዝ ገንዘብ ሰሪሐን ንኽርብሓ ዘኽእል ናይ ስነቁጠባ ትምህርቲ ተዋሂቡወን።"
          />
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="wfAlDO6t9EA"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 1, Hagere Selam, Tigray፣ 2023
            ረዲኤት ንተጋሩ ደቂኣንስትዮ  ምስ ማሕበር ደቂኣንስትዮ ትግራይ ኣብ ሃገረሰላም ትግራይ ተረኺባ፣ ኣዴታት እግሪ ክተኽላ ኣቐዲማ ብሰብሙያ ዝተሓገጸ ናይ ስነልቦና ሕውየት ንኽረኽባ፣ ከምኡውን እግሪ 
            መትከሊ እንካብ ረዲኤት ዝወሃበን ገንዘብ ብኸመይ ኣገባብ ኣብስራሕ ከምዘውዕለኦ ዘኽእለን ናይ ስነቁጠባ ትምህርቲ ይወሃበን።"
          />
        </div>
        <div className="gallery_item"> </div>
        <div className="gallery_item"> </div>
        <div className="gallery_item"> </div>
      </div>
    </div>
  );
};

export default VideosUSA;
