import React from "react";
import { NavLink } from "react-router-dom";
import YoutubeEmbed from "./YoutubeEmbed";

const Videos = () => {
  return (
    <div className="main-content">
      <div className="submenu">
        <div>
          <NavLink to="/gallery/videos/adigrat">Adigrat</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/videos/adishihu">Adishihu</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/videos/enticho">Enticho</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/videos/hagereselam">Hagere Selam</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/videos/mekelle">Mekelle</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/videos/shire">Shire</NavLink>
        </div>
        <div>
          <NavLink to="/gallery/videos/USA">USA</NavLink>
        </div>
      </div>
      <div className="summary">
        <div className="summary-text">
          <p>
            We have videos taken in in different of Tigray. These include
            training, interviews, and development actiities. Videos showing
            Rediet activities by Redeit members are also included. Videos are
            arranged by location where the activity took place.
          </p>

          <p>
            For a complete list fof Rediet videos on Youtube visit{" "}
            <NavLink
              to="https://www.youtube.com/@RedietForTegaruWomen/videos"
              target="_blank"
            >
              {" "}
              Redit Videos
            </NavLink>{" "}
            here
          </p>
        </div>
        <div>
          <YoutubeEmbed
            embedId="FFW-O7V8prU"
            inputWidth="360"
            inputHeight="300"
            caption="Rediet Documentary"
          />
        </div>
      </div>
    </div>
  );
};

export default Videos;
