import React from "react";
import "./modal.css";
import picnic from "../images/RedietFamilyPicnic.jpeg";

const Announcement = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h4>Rediet for Tegaru Women Picnic</h4>
        <p>For Rediet members and friends in Washington DC area (MD, DC, VA)</p>

        <div className="gallery-item">
          <figure>
            <img
              className="responsive announcement"
              src={picnic}
              alt="Rediet Motto"
            />
          </figure>
          <br />
          <button
            type="button"
            onClick={onClose}
            className="btn btn-warning btn-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
