import React from 'react'
import { NavLink } from 'react-router-dom'

const Volunteer = () => {
  return (
    <div className="main-contant">
       <p>
          You can also volunteer in any way you can. You can help by informing
          others to know us better and help us. You can help distributing fliers. You
          can sale raffles whenever Rediet has one. No limit to the way you can
          help. We would like to hear how you would like to volunteer. You can
          <NavLink to="/contact"> contact Rediet </NavLink>
          by phone, email, or regular mail. You will change the lives of these victims
          for ever and make a significant difference.
        </p>
      </div>
  )
}

export default Volunteer