import React from "react";
import { NavLink } from "react-router-dom";

const Involvement = () => {
  return (
    <div className="main-content">
      <div className="submenu">
        <div>
          <NavLink to="/involvement/membership">Membership</NavLink>
        </div>
        <div>
          <NavLink to="/involvement/volunteer">Volunteering</NavLink>
        </div>
        <div>
          <NavLink to="/involvement/donate">Donation</NavLink>
        </div>
      </div>
      <div className="summary">
        <p>
          Do you want to be a member of Rediet for Tegaru Women by contribting
          just US$10 a month and save lives and help the victims of the Tigray
          Genocidal war help themselves? If you do please{" "}
          <NavLink
            to="https://docs.google.com/forms/d/e/1FAIpQLSfcH7N6eknkGoy0QTJ-J8JgpUjWoGdtZx5koPgXFBSufa7_7A/viewform?pli=1"
            target="_blank"
          >
            register as a Rediet member
          </NavLink>
          . You can also
          <NavLink to="/contact"> contact Rediet </NavLink>
          by phone, email, or regular mail. You will change the lives of these
          victims for ever and make a significant difference.
        </p>

        <p>
          You can also volunteer in any way you can. You can help by informing
          others to know us better and help us. You can help distributing
          fliers. You can sale raffles whenever Rediet has one. No limit to the
          way you can help. We would like to hear how you would like to
          volunteer. You can
          <NavLink to="/contact"> contact Rediet </NavLink>
          by phone, email, or regular mail. You will change the lives of these
          victims for ever and make a significant difference.
        </p>

        <p>
          You can also make a <NavLink to="/donate"> donation </NavLink> of any
          amount you can. Every penny helps and we are grateful.{" "}
        </p>
      </div>
    </div>
  );
};

export default Involvement;
