import React from "react";

const ProjectsPlanned = () => {
  return (
    <div>
      <h3>Short Term</h3>
      <p>
        Support 300 Women households to start their own mini businesses and to
        make them self reliant. Fund these women for their mini businesses and
        provide them the necessary training to achieve this goal.
      </p>

      <h3>Long Term:</h3>
      <p>
        To enable the needy women of Tigray not only to be self supporting (self sufficient) but
        contributing to the economy in Tigray and to the society by creating
        jobs and supporting others. In order to achieve this the current mini
        entrepreneurs should improve the source of their financial resources either by
        working in groups or by improving/expanding their investment
        methodology. Rediet for Tegaru will be on their side all the way on
        this.
      </p>
    </div>
  );
};

export default ProjectsPlanned;
