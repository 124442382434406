import React from "react";
import { NavLink } from "react-router-dom";
//require('dotenv').config();
// import extCongfig from './ExternalConfig'

const About = () => {
  return (
    <div className="main-content">
      <div className="submenu" id="about">
        <div>
          <NavLink to="/about/mission">Our Mission</NavLink>
        </div>
        <div>
          <NavLink to="/about/whoweare">Who We Are</NavLink>
        </div>
        <div>
          <NavLink to="/about/whatwedo">What We Do</NavLink>
        </div>
        <div>
          <NavLink to="/about/bylaws">Bylaws</NavLink>
        </div>
        <div>
          <NavLink to="/about/testimonials">Testimnonials</NavLink>
        </div>
        <div>
          <NavLink to="/contact">Contact Us</NavLink>
        </div>
      </div>
      <div className="column">
        <p>
          Rediet for Tegaru Women (Rediet) is a non-governmental, non-political,
          and not-for-profit charity organization with approved 501(C) (3) tax
          exempt status in the United States incorporated to support women
          heavily affected by the genocidal war in Tigray, Northern Ethiopia.
          Rediet started when a group of diaspora community organizers,
          primarily mothers, recognized that the burden of war in Tigray,
          northern Ethiopia, would be most felt by women, especially by women
          heads of households. Who lost their income and means to support their
          families during the war. Organizers created a community-based
          nonprofit called Rediet for Tegaru Women in 2021. The mission of
          Rediet is to provide financial aid for women, prioritizing displaced
          women heads of household and helping women affected by the war in
          Tigray become economically self-supporting.
        </p>
        <p>
          Since its incorporation in 2021 Rediet for Tegaru Women has been
          helping families priorizing the most affected ones identified by
          experts on the ground. The mission of Rediet is to provide financial
          aid for women, prioritizing displaced women heads of household and
          helping women affected by the war in Tigray to become economically
          self-supporting. It has been providing them with cash monthly to feed
          their families and then training and re-establishing them so that they
          become self sufficient and self reliant.
        </p>
        <p>
          Working with the Women’s Association of Tigray, a nonprofit and a non
          governmentat entity working within Tigray, Rediet for Tegaru Women,
          for nearly three years, has financially supported over 190 women heads
          of households. Since the signing of the Pretoria agreement between the
          Federal Government of Ethiopia and the Tigray People's Liberation
          Front in October 2022, Rediet has been fouced on training women heads
          of households on entrepreneurship fundamentals and giving them seed
          funds to start and help them to establish their own small enterprises.
        </p>
      </div>
      <div>
        {/* <p>Testing the external environment variables</p>
        <h3>API URL : {extCongfig().apiUrl}</h3>
        <h3>Environment: {extCongfig().environment}</h3>
        <h3>Application Name: {extCongfig().application}</h3> */}
      </div>
    </div>
  );
};

export default About;
