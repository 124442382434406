import React from "react";

//  images (photos from enticho)
import enticho01 from "../images/enticho/enticho_1972.jpg";
import enticho02 from "../images/enticho/enticho_1973.jpg";
import enticho03 from "../images/enticho/enticho_1974.jpg";
import enticho04 from "../images/enticho/enticho_PHOTO-2024-01-15-23-14-37.jpg";

const PhotosEnticho = () => {
  return (
    <div className="main-content">
          <div className="gallery_group">
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={enticho01}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Training is part of the package provided first before giving
                  them the seed fund to help them invest it wisely.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={enticho02}
                  alt="Rediet Motto"
                />
                <figcaption>
                  <i>"To save Tigray save the mothers!"</i> - Rediet's motto.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={enticho03}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery_group">
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={enticho04}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
            {/* <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={enticho05}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={enticho06}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div> */}
      </div>
    </div>
  );
};

export default PhotosEnticho;
