import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosAdishihu = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="lCPV3CHBMwY"
            inputWidth="360"
            inputHeight="338"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="MdYnRcDH9Xk"
            inputWidth="360"
            inputHeight="338"
            caption="Rediet for Tegaru Women, Training Part 10, Adishihu, Tigray
            ረዲኤት ንተጋሩ ደቂ-ኣንስትዮ ንትርፊ ዘይትሰርሕ ገባሪት ሰናይ ትካል እያ። ሰናይ ተግባር ናይ ረዲኤት፣ ኣዴታት ትግራይ ብኢኮኖሚ ዓርሰን ክኽእላ፣ ክዓብያ ክትሕግዝን ከተብቅዕን እዩ ዓላማኣ። 
            እዚ ኣብ ተግባር ንምውዓል ድሕሪ ኣፋፍኖት ሰላም ኣብትግራይ ምርካብ፣ ኣብዝተፋላለየ ወረዳታት ትግራይ ዓቕማ ዝፈቐዶ ሓጋዚ ዘይብለን፣ ንስራሕ መበገሲ ንኡሽተይ ገንዘብ እንተረኺበን ክሰርሓ 
            ዓቕሚን ድልየትን ዘለወን ሓለፍቲ ስድራ ኣዴታት ኣብምሕጋዝ ትርከብ። ዕላማ ረዴት ኣብባይታ ንኽትግበር ማሕበር ደቂ ኣንስትዮ ትግራይ ዓብይ ግደ ኣለወን። እዚ ቪዲዮ ኣብ ዓዲሽሁ እግሪ  
            ክተኽላ ስልጠና ዝወስዳ ኣዴታት፣ ርእይቶ፣ ተሞክሮ እንተካፍላ እዩ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="6-_7hTWDbK4"
            inputWidth="360"
            inputHeight="338"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="PE0gFpZjEOI"
            inputWidth="360"
            inputHeight="338"
          />
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="3g99bMzCGsc"
            inputWidth="360"
            inputHeight="338"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="D5pIlRGOs24"
            inputWidth="360"
            inputHeight="338"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="r4ZS4Hbpd3k"
            inputWidth="360"
            inputHeight="338"
          />
        </div>

        <div className="gallery_item">
          <YoutubeEmbed
            embedId="HNTcahK_WFg"
            inputWidth="360"
            inputHeight="338"
            caption="ረዲኤት ንተጋሩ ደቂኣንስትዮ እትሕግዘን ደቂ ኣንስትዮ እግሪ ንምትካል ንኽበቕዓ ምስ ማሕበር ደቂኣንስትዮ ትግራይ ብምትሕብባር ኣብ ዓዲሽሁ 
            ትግራይ ዝተካየደ ናይ ስነልቦናዊ ሕውየት፣ ናይ ስራሕ ምልዕዓልን ምብርትታዕን ናይ ሰለስተ ማዓልቲ ስልጠና። ኣዲሽሁ፣ትግራይ 2023. 
            20231026"
          />
        </div>
      </div>
    </div>
  );
};

export default VideosAdishihu;
