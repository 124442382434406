import React, { useEffect } from "react";
import { useState } from "react";
//import homepage_picture from "../images/mekelle/mekelle_rediet_in_action.jpg";

import Announcement from "./Announcement";
import HomeLeft from "./HomeLeft";
import HomeLeftInner from "./HomeLeftInner";
import HomeRightInner from "./HomeRightInner";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(true); // Initially open

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //  Show this announcement until Oct 13, 2014 only
  const [showAnnouncment, setShowAnnouncment] = useState(true);
  const targetDate = new Date("2024-10-13"); // Stop rendering coponent after this date

  useEffect(() => {
    const today = new Date();

    if (today > targetDate) {
      setShowAnnouncment(false);
    }
  }, []);

  return (
    <div>
      {showAnnouncment && (
        <Announcement isOpen={isModalOpen} onClose={closeModal} />
      )}
      <div className="main rediet-flex-direction">
        <div>
          <HomeLeftInner />
        </div>
        <div>
          <HomeRightInner />
        </div>
      </div>
    </div>
  );
};

export default Home;
