import React from "react";
//import { NavLink } from "react-router-dom";
import festival_photo from "../../components/images/usa/Tigray_festival_event_July_2023.jpg";

const LeftInner = () => {
  return (
    <div className="left-inner">
      <img
        src={festival_photo}
        width={`100%`}
        height={`auto`}
        className="responsive"
        alt="Selam and Eldana serving light refreshments"
      />
      <div className="picture-desc">
        <p>
          Selam Tesfay and Eldana Kibrom serving light refreshments during a
          Rediet fundraising event.
        </p>
        <p>July 2022, Washington DC.</p>
      </div>
    </div>
  );
};

export default LeftInner;
