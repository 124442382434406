import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosAdigrat = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="1Dj6AN62SpQ"
            inputWidth="360"
            inputHeight="300"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="Od3FYBpBjek"
            inputWidth="360"
            inputHeight="300"
            caption="Rediet for Tegaru Women, Training, Adigrat, Tigray Part 8
            ትካል ረዲኤት ንተጋሩ ደቂኣንስትዮ ኣብ ዝተፈላለየ ወረዳታት ትግራይ ሓጋዚ ንዘይብለን ሓለፍቲ ስድራ ኣደታት እግሪ ንኽተትክል 
            ምስ ማሕበር ደቂኣንስትዮ ትግራይ ብምትሕብባር ዝተገበረ ናይ ስንልቦና ሕውየት፣ ናይ ንግዲን ንስራሕ ምልዕዓልን ኣብ ዓዲግራት 
            ዝተካየደ ስልጠና። 
            2023 ዓዲግራት፣ ትግራይ"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="0i7FPAajVgw"
            inputWidth="360"
            inputHeight="300"
            caption="Rediet for Tegaru Women, Training, Adigrat, Tigray Part  11
              ረዲኤት ንተጋሩ ደቂኣንስትዮ ኣብ ዓመተ 2023 ኣብ ሽዱሽተ ወረዳታት ትግራይ ነንሕድሕደን 30 ሓለፍቲ 
              ስድራ ሓጋዚ ዘይብለን ኣዴታት ነንሕድሕደን 20,000 ብር መበገሲ ዝኸውን ሂባ ስራሕ ኣጀሚራተን።
              ነዚ ድማ እንካፍቲ መስካሕክሒን ጽንተታዊ ኲናት ወጺኤን ንኽሰርሓ ዝሕግዘን ናይ ስነልቦናዊ ሕውየት፣ ናይ ቁጠባን 
              ንግዲን ሙያ ከምኡውን ንስራሕ ንኽለዓዓላ ዝሕግዘን ምስ ማሕበር ደቂ ኣንስትዮ ትግራይ ብምትሕብባር ብሰብሙያ 
              ናይ ሰለስተ ማዓልቲ ስልጠናታት ወሲደን ኣብስራሕቲ ተዋፊረን ይርከባ። እዛ ቪዲዮ ሓንቲ ካባተን ኣብ ዓዲግራት ዝነበረት እያ።"
          />
        </div>

        <div className="gallery_item">
          <YoutubeEmbed
            embedId="uMJe3ZBM3EU"
            inputWidth="360"
            inputHeight="300"
            caption="Rediet for Tegaru Women, Training, Adigrat, Tigray Part 10 ኣብቲ ኣብ ዓዲግራት 
          ኣብዝተኻየደ ስልጠና ሓንቲ ሰልጣኒት ኣዶ ርእይቶ እንትትህብ ዘርእይ፣"
          />
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="-Qy1GjKJia0"
            inputWidth="360"
            inputHeight="300"
            caption="Rediet for Tegaru Women, Training, Adigrat, Tigray Part 9 ረዲኤት ንተጋሩ ደቂኣንስትዮ ሓገዝ ኣብዝገበረትለን ወረዳታት ዝተገበር ሓገዝ፣
            ናይ ስነልቦና ሕውየት ንኽረኽባ፣ ናይ ንግዲን ቁጠባን ትምህርቲ፣ ከም ኡውን ንስራሕ ንኽለዓዓላ ዝሕግዘንን ስልጠና ብሰብመያ ምስወሰዳ፣ መበገሲ ስራሕ ዝኸውን ነንሕድሕደን 
            ኣዴታት 20,000 ብር ተዋሁበን ኣብ ስራሕ ተዋፊረን። እዚ ቪዲዮ ኣብ ኣዋን ስልጠና እንካብ ሰልጠንቲ ሓንቲ ኣዶ ርእይቶ እንትትህብ ዘርእይ እዩ።"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="xG3tdczWqdA"
            inputWidth="360"
            inputHeight="300"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="LYoVACxx59A"
            inputWidth="360"
            inputHeight="300"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="bVlDxKYugNM"
            inputWidth="360"
            inputHeight="300"
          />
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="dE2HIVHaeg4"
            inputWidth="360"
            inputHeight="300"
          />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="npN0jX7EzTE"
            inputWidth="360"
            inputHeight="300"
          />
        </div>

        <div className="gallery_item">{/* when available add one here */}</div>
        <div className="gallery_item">{/* when available add one here */}</div>
      </div>
    </div>
  );
};

export default VideosAdigrat;
