import React from "react";

//  images (photos from mekelle)
import mekelle01 from "../images/mekelle/mekelle_IMG_20230323_095159.jpg";
import mekelle02 from "../images/mekelle/mekelle_IMG_20230501_102031.jpg";
import mekelle03 from "../images/mekelle/mekelle_IMG_20230501_104949.jpg";
import mekelle04 from "../images/mekelle/mekelle_IMG_20230519_100254.jpg";
import mekelle05 from "../images/mekelle/mekelle_IMG_20230519_100257.jpg";
//import mekelle06 from "../images/mekelle/mekelle_IMG_20230323_095219.jpg";
import mekelle06 from "../images/mekelle/mekelle_IMG_20230323_095622.jpg";
const PhotosMekelle = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery_item">
          <figure>
            <img className="responsive" src={mekelle01} alt="Rediet Motto" />
            <figcaption>
              Training is part of the package provided first before giving them
              the seed fund to help them invest it wisely.
            </figcaption>
          </figure>
        </div>
        <div className="gallery_item">
          <figure>
            <img className="responsive" src={mekelle02} alt="Rediet Motto" />
            <figcaption>
              <i>"To save Tigray save the mothers!"</i> - Rediet's motto.
            </figcaption>
          </figure>
        </div>
        <div className="gallery_item">
          <figure>
            <img className="responsive" src={mekelle03} alt="Rediet Motto" />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery_item">
          <figure>
            <img className="responsive" src={mekelle04} alt="Rediet Motto" />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery_item">
          <figure>
            <img className="responsive" src={mekelle05} alt="Rediet Motto" />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery_item">
          <figure>
            <img className="responsive" src={mekelle06} alt="Rediet Motto" />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default PhotosMekelle;
