// rafce - reactArrowfunctionComponentExport
// Refereces https://www.youtube.com/watch?v=23BHwAFIZmk&ab_channel=Tech2etc
//import redietlogo from "../images/rediet-logo-nobg.png";
import redietlogo from "../images/rediet-logo-nobg.png";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./navbar.css";

const navigation = [
  { name: "Home", href: "/home" },
  { name: "About", href: "/about" },
  { name: "Projects", href: "/projects" },
  { name: "Get Involved", href: "/involvement" },
  { name: "Gallery", href: "/gallery" },
  { name: "Donate", href: "/donate" },
  { name: "Contact", href: "/contact" },
];

const Navbar = () => {
  /*
    On initial load we would like to have the 'Home' page auto slected or  become active
    After that once a link is clicked - the becomes the active one, Home becomes in active.
    To make Home active again it must be clicked like any other menu items.
  */
  const [initialLoad, setInitialLoad] = useState(true);
  const [menuItemSelected, setMenuItemSelected] = useState(false);
  const location = useLocation();

  return (
    <section>
      <div>
        <nav className="navigation">
          <div className="logo-name">
            <div className="logo">
              <img className="image" src={redietlogo} alt="Rediet Logo" />
            </div>
            <div className="org">
              <div className="name-eng">Rediet for Tegaru Women</div>
              <div className="name-geez">ረዲኤት ንተጋሩ ደቀንስትዮ</div>
            </div>
          </div>
          <div className="navbar">
            <ul
              id="navbar"
              className={menuItemSelected ? "navbar active" : "navbar"}
            >
              {navigation.map((menuItem) => (
                <li key={menuItem.name}>
                  <NavLink
                    className={
                      menuItem.name === "Home" &&
                      initialLoad &&
                      location.pathname === "/"
                        ? "active"
                        : ""
                    }
                    to={menuItem.href}
                    onClick={() => {
                      setMenuItemSelected(!menuItemSelected);
                      setInitialLoad(false);
                    }}
                  >
                    {menuItem.name}
                  </NavLink>
                </li>
              ))}
            <li><NavLink className={"fa-solid fa-solid fa-envelope"}  to="https://mail.hostinger.com/" target="_blank" /> </li>
            </ul>
          </div>
          <div id="mobile">
            <i
              id="bar"
              onClick={() => setMenuItemSelected(!menuItemSelected)}
              className={menuItemSelected ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Navbar;
