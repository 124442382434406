import React from "react";

//  images (photos from adishihu)
import adishihu01 from "../images/adishihu/adishihu_20231026_100117.jpg";
import adishihu02 from "../images/adishihu/adishihu_20231026_100127.jpg";
import adishihu03 from "../images/adishihu/adishihu_20231026_110926.jpg";
import adishihu04 from "../images/adishihu/adishihu_20231027_152359.jpg";

const PhotosAdishihu = () => {
  return (
    <div className="main-content">
          <div className="gallery_group">
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={adishihu01}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Training is part of the package provided first before giving
                  them the seed fund to help them invest it wisely.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={adishihu02}
                  alt="Rediet Motto"
                />
                <figcaption>
                  <i>"To save Tigray save the mothers!"</i> - Rediet's motto.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={adishihu03}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="gallery_group">
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={adishihu04}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
            {/* <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={adishihu05}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div>
            <div className="gallery-item">
              <figure>
                <img
                  className="responsive"
                  src={adishihu06}
                  alt="Rediet Motto"
                />
                <figcaption>
                  Seed fund ready to help the needy women to established their
                  choice of mini-investiment.
                </figcaption>
              </figure>
            </div> */}
      </div>
    </div>
  );
};

export default PhotosAdishihu;
