/* 
    source:  https://dev.to/bravemaster619/simplest-way-to-embed-a-youtube-video-in-your-react-app-3bk2
*/

import React from "react";

const YoutubeEmbed = ({ embedId, inputWidth, inputHeight, caption }) => {
  //if no sizes are provided use default values
  if (!inputWidth) {
    inputWidth = "853";
  }
  if (!inputHeight) {
    inputHeight = "480";
  }
  if (!caption) {
    caption =
      "Rediet for Tegaru Women in action -- spoken langauage is Tigrigna";
  }

  return (
    <div className="video-responsive">
      <iframe
        className="responsive-iframe"
        width={inputWidth}
        height={inputHeight}
        src={`https://www.youtube.com/embed/${embedId}?rel=0`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube video"
      />
      <figcaption>
        <i>{caption}</i>
      </figcaption>
    </div>
  );
};

export default YoutubeEmbed;
