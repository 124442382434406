import React from "react";
// import PhotoEmbed from "./PhotoEmbed";

//  images (photos from adigrat)
// import adigrat01 from "../images/adigrat/adigrat_857A4628.JPG";
// import adigrat02 from "../images/adigrat/adigrat_857A4633.JPG";
// import adigrat03 from "../images/adigrat/adigrat_857A4638.JPG";
// import adigrat04 from "../images/adigrat/adigrat_857A4657.JPG";
// import adigrat05 from "../images/adigrat/adigrat_1837.jpg";
// import adigrat06 from "../images/adigrat/adigrat_1859.jpg";

const PhotosAdigrat = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_857A4628.JPG")}
              alt="Rediet Motto"
            />
            <figcaption>
              Training is part of the package provided first before giving them
              the seed fund to help them invest it wisely.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_857A4633.JPG")}
              alt="Rediet Motto"
            />
            <figcaption>
              <i>"To save Tigray save the mothers!"</i> - Rediet's motto.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_857A4638.JPG")}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>
      <div className="gallery_group">
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_857A4657.JPG")}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_1837.jpg")}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_1859.jpg")}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
      </div>

      <div className="gallery_group">
        {/* <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_857A4657.JPG")}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div>
        <div className="gallery-item">
          <figure>
            <img
              className="responsive"
              src={require("../images/adigrat/adigrat_1837.jpg")}
              alt="Rediet Motto"
            />
            <figcaption>
              Seed fund ready to help the needy women to established their
              choice of mini-investiment.
            </figcaption>
          </figure>
        </div> */}
        {/* <div className="gallery-item">
          <PhotoEmbed 
          photoId="AF1QipM5c2HmdPM78lpgNNQS-tkNWXPgP2xCaQiexoy8" 
          description="Getting picture from Google Photos!"/>
        </div> */}
      </div>
    </div>
  );
};

export default PhotosAdigrat;
