import React from "react";
import hagereselam01 from "../images/hagereselam/hagereselam_IMG_5239.JPG";

const ProjectsCompletedHagereSelam = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
        <div>
          <p>
            Trauama as a result of the Tigray Genocide has significant impact on
            the lives of every one in Tigray. Women are not an exception. May
            have been raped, killed, displaced, their properies destroyed and or
            looted. The problems are endless and it is beleived the first in the
            rehabilitation of these women the first action is to provide them
            with some sort of sysncolgical treament. Without this type of
            treament any finalancial support provided might mnot be effective.
          </p>

          <p>
            With that in mind the first action Rediet for Tegaru Women (Rediet
            in short) took was to provide speciall short term training to
            selected group of individuals. Once they are found to be capable of
            running a mini businesss seed fund was provided to them.
          </p>

          <p>
            These was provided to around 30 war displaced and needy women who
            were living in Mekelle and the environs. The type of training needed
            and duration was determined in consulation with the academic
            institutions in Tigray and the Women's Association of Tigray. The
            training fee (although most instructors provided the for almost
            free), perdiem, and the expenses for meals was provided by Rediet.
          </p>

          <p>
            Before, during, and after after completion of the training each
            individual trainee came up with their propopsals on what they want
            to do with the seed money that will be provided to them. This was
            further discussed with the experts on the ground and the Women
            Association of Tigray to valiadate the viability of the propposed
            projects.
          </p>
          <p>
            Finally each was given the necessary seed fund and they started
            their own mini-investments. The women's assication continously
            follows and minotors their progress and they provide advice if
            needed.
          </p>
        </div>
        <div className="gallery-item">
          <figure>
            <img className="responsive" src={hagereselam01} alt="Rediet Motto" />
            <figcaption>
              Training is part of the package provided first before giving them
              the seed fund to help them invest it wisely.
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCompletedHagereSelam;
