import React from 'react'

const Audio = () => {
  return (
    <div className="main-content">
      <h4>We do not have audio recording at this time.  We will update this ppage with Rediet related audio recordings when such media become available.  </h4>
    </div>
  )
}

export default Audio