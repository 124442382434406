import React from "react";
import { NavLink } from "react-router-dom";

const HomeRightInner = () => {
  return (
    <div className="right-inner">
      <div className="text">
        <h2>
          {" "}
          Rediet<span className="name-geez">(ረዲኤት)</span>
        </h2>
        <p>
          Rediet for Tegaru Women (Rediet) is a non-governmental, non-political,
          and not-for-profit humanitarian organization. It has Internal Revenue
          Service (IRS) approved 501(C) (3) tax exempt status in the United
          States. It is incorporated to support women heavily affected by the
          genocidal war in Tigray, Northern Ethiopia. Rediet (ረዲኤት) in{" "}
          <NavLink
            to="https://www.britannica.com/topic/Geez-language"
            target="_blank"
          >
            Ge'ez language
          </NavLink>{" "}
          means help.
        </p>
        <p>
          Since its incorporation in Augist 2021, Rediet for Tegaru Women has
          been helping families prioritizing the most affected ones identified
          by experts on the ground. It has been providing them cash monthly to
          feed their families and then training and re-establishing them so that
          they become self sufficient and self reliant.
        </p>
        <p>
          A few individual organizers created the community-based , Rediet for
          Tegaru Women, in 2021. The mission of Rediet is to provide financial
          aid for women, prioritizing displaced women heads of households and
          helping women affected by the war in Tigray to become economically
          self-supporting. Working closely with the Women’s Association of
          Tigray, a local non-governmental, non-profit organization in within
          Tigray, Rediet for Tegaru Women, for nearly two years, has financially
          supported over 30 women heads of households. Since start of the
          cessation of hostilities and the signing of Pretoria Peace Agreement
          between the Federal Government of Ethiopia and the Tigray Peoples
          Liberation Front in October 2022, Rediet has pivoted by training women
          heads of households on entrepreneurship fundamentals and giving them
          seed funds to start their own small enterprises.
        </p>
      </div>
    </div>
  );
};

export default HomeRightInner;
