import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const VideosMekelle = () => {
  return (
    <div className="main-content">
      <div className="gallery_group">
      <div className="gallery_item">
          <YoutubeEmbed
            embedId="ziHJyUSdvHo"
            inputWidth="360"
            inputHeight="338"
            />
        </div>

        <div className="gallery_item">
          <YoutubeEmbed
            embedId="-tzDX-xCzwc"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="OyBsjBXYsos"
            inputWidth="360"
            inputHeight="338"
           />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="OyBsjBXYsos"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
      </div>
      {/* <div className="gallery_group">
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="dHdRlpnDZSk"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="nX8Q2z3GoXY"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="nX8Q2z3GoXY"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
        <div className="gallery_item">
          <YoutubeEmbed
            embedId="nX8Q2z3GoXY"
            inputWidth="360"
            inputHeight="338"
            />
        </div>
      </div> */}
    </div>
  );
};

export default VideosMekelle;
